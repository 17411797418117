<template>
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserPage',

  meta () {
    return {
      titleTemplate: title => `${title} - Utilisateurs | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    return new Promise((resolve, reject) => {
      if (!store.getters['users/getFormFields']) {
        store.dispatch('users/fetchFormFields').then(resolve)
      } else { resolve() }
    }).then(() => {
      if (currentRoute.params.id) {
        store.dispatch('users/getUser', currentRoute.params.id)
      }
    })
  },

  beforeDestroy () {
    this.$store.commit('users/setCurrentUser', null)
  },

  computed: {
    ...mapGetters({
      user: 'users/getCurrentUser'
    })
  },

  watch: {
    user (newVal, oldVal) {
      this.$store.commit('pages/setPageTitle', newVal['display_name'])
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('users.users_list'), to: { name: 'users-list' } },
      {}
    ])
    if (!this.$route.params.id) {
      this.$store.commit('pages/setPageTitle', this.$t('users.add_user'))
    }
  }
}
</script>

<style>
</style>
